<template>
  <div class="modal__bg--build" @click="closeModal">
    <div class="modal__container-md--build" @click.stop>
      <h2 class="tp-head-responsive--build">{{ $t('modalreschedule.txt_reprogramar_reserva') }}</h2>
      <p class="tp-body-responsive--build">{{ $t('modalreschedule.txt_la_reprogramacion_sera_disponible') }}</p>
      <div class="reschedule__componets">
        <date-picker
            v-if="isClient"
            value-type="YYYY-MM-DD HH:mm"
            format="DD-MM hh:mm a"
            lang="es"
            type="datetime"
            :placeholder="$t('placeholder.txt_fecha')"
        >
        </date-picker>
        <WhiteSelectComponent
            :optionsSelect="listTimes"
            :bg_color="'white'"
            :required=true
            :placeholder="$t('placeholder.txt_horario')"
            :valueObj="'name'"
            v-model="selectTime"
        >
        </WhiteSelectComponent>
        <WhiteSelectComponent
            :optionsSelect="listAreas"
            :bg_color="'white'"
            :required=true
            :placeholder="$t('placeholder.txt_zona')"
            :valueObj="'name'"
            v-model="selectArea"
        >
        </WhiteSelectComponent>
      </div>
      <div class="modal__buttons--build">
        <ButtonTexto
            type="button"
            @click="closeModal"
        >
          {{ $t('dropdownaddress.txt_regresar') }}
        </ButtonTexto>
        <ButtonPrimary
            type="button"
        >
          {{ $t('modalreschedule.txt_reprogramar') }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineEmits, ref, onMounted } from "vue"
  import ButtonTexto from "@/components/new/buttons/ButtonTextoConstructor.vue";
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
  import WhiteSelectComponent from "@/components/new/inputs/WhiteSelectComponent.vue";

  let listTimes = ref([]);
  let listAreas = ref([]);
  let selectTime = ref(null);
  let selectArea = ref(null);
  let isClient = ref(false);

  onMounted(() => {
    isClient.value = true;
  })

  const emit = defineEmits(['close']);

  const closeModal = () => emit('close');
</script>

<style scoped>
h2,
p{
  text-align: center;
}

.reschedule__componets{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.reschedule__componets > *{ flex: 1 0 8rem; }
</style>