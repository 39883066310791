<template>
  <article class="carta__card--build" data-aos="fade-up">
    <img
        loading="lazy"
        :alt=category.name
        :srcset="
                  `
                  ${category.img}?width=200 200w,
                  ${category.img}?width=400 400w
                  `
              "
        :src=category.img
        sizes="(max-width: 400px) 100vw, 400px"
    />
    <div class="carta__nombre--build">
      <div class="carta__informative--build">
        <h3 class="tp-head-responsive--build">{{ category.name.length > 40 ? category.name.slice(0, 40) : category.name }}</h3>
        <ButtonPrimary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonPrimary>
      </div>
    </div>
  </article>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonPrimary},
  props: ['category'],
}

</script>

<style scoped>
.carta__card--build{
  position: relative;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / .6;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  background-color: var(--primary--build);
}

.carta__card--build img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carta__nombre--build{
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  background: linear-gradient(360deg, rgba(var(--complementary-rgb--build), 1) 0%, rgba(var(--complementary-rgb--build), 0) 50%);
  transition: all .3s linear;
}

.carta__informative--build{
  position: relative;
  bottom: -50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 10px;
  transition: all .3s linear;
}

.carta__card--build h3{
  display: inline-block;
  color: var(--text-white--build);
  margin-top: auto;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  transition: width .3s linear;
}

.carta__nombre--build button{
  width: fit-content;
}

@media screen and (min-width: 600px){
  .carta__card--build{
    width: 48%;
  }
}

@media screen and (min-width: 905px){
  .carta__card--build{
    width: 32%;
  }

  .carta__informative--build{
    bottom: -70px;
    gap: 20px;
  }
}

@media screen and (min-width: 1240px){
  .carta__card--build{
    width: 31%;
  }

  .carta__card--build:hover .carta__nombre--build{
    box-shadow: inset 0 0 400px var(--complementary--build)
  }

  .carta__card--build:hover .carta__informative--build{
    bottom: 0;
  }

  .carta__card--build:hover h3{
    width: 90%;
    text-align: center;
    margin-top: initial;
  }

  .carta__card--build:not(:hover) h3 {
    width: 100%;
    text-align: left;
  }
}

@media screen and (min-width: 1440px){
  .carta__card--build{
    width: 32%;
  }

  .carta__nombre--build{
    padding: 16px;
  }

  .carta__informative--build{
    bottom: -90px;
    padding: 10px 0;
  }
}
</style>