<template>
  <button
      class="button--build tp-button-responsive--build"
      :class="{ 'button-padding-left': hasSvg }"
      :disabled="disabled || loading"
  >
    <SpinnerComponent
        :spinner-color="'var(--text-white--build)'"
        :type="'small'"
        v-if="loading"
    />
    <slot></slot>
  </button>
</template>

<script setup>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
import { button } from "@/composables/button";
import { defineProps, useSlots } from 'vue';

defineProps(['disabled', 'loading']);

const { hasSvg } = button(useSlots());
</script>

<style scoped>
button{
  background-color: var(--secundary--build);
  border: 1px solid var(--secundary--build);
  color: var(--text-black--build);
}
</style>