<template>
  <p class="tp-body-responsive--build">{{ $t('perfil.txt_en_esta_seccion_encontaras') }}</p>
  <div class="view-perfil-datos">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
</style>