<template>
  <div class="badge-base--build tp-body-responsive--build"
       v-if="status !== null && status !== undefined"
       :class="status==='caution'? 'badge-caution': 'badge-danger'"
  >
    <slot></slot>
  </div>
  <div class="badge-base--build tp-body-responsive--build" v-else>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "StatusBadge",
  components: {

  },
  props: ['status'],
};
</script>

<style scoped>
</style>