<template>
  <label class="tp-body-responsive--build" :for="id">
    <input
        type="checkbox"
        :value="modelValue"
        :id=id
        :required="required"
        :disabled="disabled"
        :checked="modelValue"
        @change="onChanged"
    >
    <slot></slot>
  </label>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "CheckboxComponent",

  emits: ['update:modelValue'],

  props: {
    modelValue: Boolean,
    id: String,
    required: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.target.checked);
    }

    return {
      onChanged
    }
  }
})
</script>

<style scoped>
label{
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
  font-size: var(--fs-body-small--build);
  font-weight: var(--regular--build);
  letter-spacing: 0.4px;
  line-height: 16px;
}

input[type="checkbox"]{
  appearance: none;
  min-width: 20px;
  height: 20px;
  background-color: transparent;
  border: 2px solid var(--border-gray--build);
  border-radius: 4px;
  transition: all .3s ease;
}

input[type="checkbox"]:checked{
  background-color: var(--primary--build);
  border: 1px solid var(--primary--build);
}

input:checked::before{
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/icons/new/action/check.svg");
  background-size: 100%;
}

@media screen and (min-width: 905px){
  label{
    font-size: var(--fs-body-medium--build);
    line-height: 20px;
    letter-spacing: 0.25px;
  }
}
</style>