<template>
  <div class="modal__bg--build">
    <div class="modal__container-lg--build">
      <img
          loading="lazy"
          role="presentation"
          alt=""
          src="@/assets/user/icon_campana.svg"
      />
      <p class="tp-body-responsive--build">{{message}}</p>
      <div class="modal__buttons--build" v-if="ok.length > 0">
        <ButtonTexto type="button" @click="$emit('no')">{{no}}</ButtonTexto>
        <ButtonPrimary type="button" @click="clickOk">{{ok}}</ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTexto from "@/components/new/buttons/ButtonTextoConstructor.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
export default {
  emits: ['sendOrder', 'ok', 'no'],
  props: ['ok', 'no', 'message', 'sendOrder'],
  components: {
    ButtonPrimary,
    ButtonTexto
  },
  methods: {
    clickOk () {
      if (this.sendOrder) {
        this.$emit('sendOrder')
      } else {
        this.$emit('ok')
      }
    }
  }

}
</script>

<style scoped >
.modal__container-lg--build{
  overflow: visible;
}

.modal__container-lg--build img{
  position: absolute;
  top: -15px;
  right: -15px;
}

.modal__container-lg--build p{
  text-align: center;
}
</style>