<template>
  <section class="productocarta--build">
    <div class="productocarta__image--build">
      <img
          loading="lazy"
          :alt=product.name
          :srcset="`${backgroundPlaceholderItem(product)}?width=200 200w`"
          :src=backgroundPlaceholderItem(product)
          sizes="(max-width: 200px) 100vw, 200px"
      />
    </div>
    <div class="productocarta__information--build">
      <div class="productocarta__text-build">
        <h3 class="tp-title-responsive--build">{{product.name}}</h3>
        <p class="tp-body-responsive--build">{{product.description}}</p>
      </div>
      <div class="productocarta__prices-btn--build">
        <div class="productocarta__prices--build">
          <p class="productocarta__discount-color--build tp-title-responsive--build" v-if="product.has_discount">S/.{{product.promo_price}}</p>
          <p class="productocarta__anterior--build tp-body-responsive--build" v-if="product.has_discount">S/.{{product.price.toFixed(2)}}</p>
          <p class="tp-title-responsive--build" v-else>S/.{{product.price.toFixed(2)}}</p>
        </div>
      </div>
    </div>
    <div class="productocarta__discount" v-if="product.has_discount">
      <p class="tp-body-responsive--build">{{parseFloat((parseFloat(product.promo_price) - parseFloat(product.price))/(parseFloat(product.price))*100).toFixed(0)}}%</p>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ProductoCarta',
  components: {},
  props: ['product'],
  methods: {

    backgroundPlaceholderItem() {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.product.img)
        if (!this.product.is_available) {
          return this.product.img;
        }
        return this.product.img;
      } catch (error) {
        if (!this.product.is_available) {
          return process.env.VUE_APP_IMG_PLACEHOLDER;
        }
        return process.env.VUE_APP_IMG_PLACEHOLDER;
      }
    },
  }
}
</script>

<style>
  .productocarta--build{
    position: relative;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(41, 41, 41, .2);
    overflow: hidden;
    background: #F6F6F6;
    cursor: pointer;
    height: 115px;
    padding: 8px;
    width: 100%;
    scale: 1;
    transition: scale .3s linear;
  }

  .productocarta__image--build{
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .plato___image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .productocarta__information--build {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-max-content;
    padding: 0 0 0 10px;
    z-index: 1;
  }

  .productocarta__information--build::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .3s linear;
    z-index: 0;
  }


  .productocarta__information--build::after {
    background: linear-gradient(0deg, rgba(246, 246, 246, 1) 40%, rgba(246, 246, 246, 0) 70%);
    opacity: 1;
  }

  .productocarta__information--build p{
    margin-top: 4px;
  }

  .productocarta__text-build{
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    height: 100%;
  }

  .productocarta__prices-btn--build{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
  }

  .productocarta__prices-btn--build button{ display: none; }

  .productocarta__prices--build{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }

  .productocarta__prices--build p{
    text-decoration: none
  }

  .productocarta__prices--build .productocarta__anterior--build{
    text-decoration: line-through
  }

  .productocarta__prices--build .productocarta__discount-color--build{
    margin-top: 0;
  }

  .productocarta__discount{
    position: absolute;
    top: 5px;
    left: 0;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--primary--build);
  }

  .productocarta__discount p{
    color: var(--text-white--build);
    font-weight: var(--semibold--build);
  }

  @media screen and (min-width: 600px){
    .productocarta--build{
      height: 105px;
    }

    .productocarta__image--build{
      width: 78px;
      min-width: 78px;
    }

    .productocarta__information--build::after {
      background: linear-gradient(0deg, rgba(246, 246, 246, 1) 20%, rgba(246, 246, 246, 0) 70%);
    }
  }

  @media screen and (min-width: 905px){
    .productocarta--build{
      height: 140px;
      padding: 0;
    }

    .productocarta--build:hover{
      scale: 1.02;
    }

    .productocarta__image--build{
      width: 140px;
      min-width: 140px;
      border-radius: 8px 0 0 8px;
    }

    .productocarta__information--build{
      padding: 8px 16px;
    }

    .productocarta__information--build::after {
      background: linear-gradient(0deg, rgba(246, 246, 246, 1) 30%, rgba(246, 246, 246, 0) 70%);
    }

    .productocarta__text-build p{
      margin-top: 6px;
    }
  }

  @media screen and (min-width: 1240px){
    .productocarta--build{
      height: 200px;
      padding: 0;
    }

    .productocarta__image--build{
      width: 200px;
      min-width: 200px;
    }

    .productocarta__text-build {
      -webkit-line-clamp: 5;
    }

    .productocarta__information--build{
      padding: 12px 16px;
    }

    .productocarta__prices--build{
      margin-left: initial;
    }

    .productocarta__discount{
      top: 15px;
    }
  }

  @media screen and (min-width: 1440px) {
    .productocarta__text-build p{
      margin-top: 10px;
    }
  }
  </style>