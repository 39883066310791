<template>
  <div class="carousel-main--build">
    <div class="carousel__container--build"
         :style="isDragging ? 'cursor: grabbing': 'cursor: grab'"
         @mousedown="startDrag"
         @touchstart="startDrag"
         @mousemove="handleDrag"
         @touchmove="handleDrag"
         @mouseup="endDrag"
         @touchend="endDrag">
      <div class="carousel__list--build"
           v-if="screenWidth < 600"
      >
        <div class="carousel__item--build"
              v-for="(number, index) in info_carousel_mobile"
              :key="index"
        >
          <picture>
            <source
                type="image/webp"
                :srcset="srcsetDesktop(number)"
                media="(min-width: 600px)"
                sizes="(max-width: 1920px) 100vw, 1920px"
            />
            <img
                loading="lazy"
                role="presentation"
                alt=""
                :src="srcsetMobile(number)"
                sizes="(max-width: 600px) 100vw, 600px"
            />
          </picture>
<!--          <div class="carousel__information desktop" :style="`background-image: url(${number.imgDesktop});`">
            <div class="image__container" :style="`background-image: url(${number.imgDesktopText})`">
              <ButtonPrimary
                  type="button"
                  @click="$emit('banner_buy')"
              >
                {{ $t('buttons.txt_comprar') }}
              </ButtonPrimary>
            </div>
          </div>
          <div class="carousel__information mobile" :style="`background-image: url(${number.imgMobile});`">
            <div class="image__container" :style="`background-image: url(${number.imgMobileText})`">
              <ButtonPrimary
                  type="button"
                  @click="$emit('banner_buy')"
              >
                {{ $t('buttons.txt_comprar') }}
              </ButtonPrimary>
            </div>
          </div>-->
        </div>
      </div>
      <div class="carousel__list--build"
           v-if="screenWidth >= 600"
      >
        <div class="carousel__item--build"
             v-for="(number, index) in info_carousel_desktop"
             :key="index"
        >
          <picture>
            <source
                type="image/webp"
                :srcset="srcsetDesktop(number)"
                media="(min-width: 600px)"
                sizes="(max-width: 1920px) 100vw, 1920px"
            />
            <img
                loading="lazy"
                role="presentation"
                alt=""
                :src="srcsetMobile(number)"
                sizes="(max-width: 600px) 100vw, 600px"
            />
          </picture>
          <!--          <div class="carousel__information desktop" :style="`background-image: url(${number.imgDesktop});`">
                      <div class="image__container" :style="`background-image: url(${number.imgDesktopText})`">
                        <ButtonPrimary
                            type="button"
                            @click="$emit('banner_buy')"
                        >
                          {{ $t('buttons.txt_comprar') }}
                        </ButtonPrimary>
                      </div>
                    </div>
                    <div class="carousel__information mobile" :style="`background-image: url(${number.imgMobile});`">
                      <div class="image__container" :style="`background-image: url(${number.imgMobileText})`">
                        <ButtonPrimary
                            type="button"
                            @click="$emit('banner_buy')"
                        >
                          {{ $t('buttons.txt_comprar') }}
                        </ButtonPrimary>
                      </div>
                    </div>-->
        </div>
      </div>
    </div>
    <div class="carousel__list-dots--build">
      <span
          class="carousel__item-dot--build"
          v-for="(item, index) in info_carousel_desktop"
          :key="index"
          :class="{ active: index === current_index }"
          @click="goToSlide(index)"
      ></span>
    </div>
  </div>

</template>

<script>
// import ButtonPrimary from './new/buttons/ButtonPrimaryConstructor.vue';

export default {
  name: "CarouselQE",
  props: {
    info_carousel_desktop: {
      type: Array,
      required: false
    },
    info_carousel_mobile: {
      type: Array,
      required: false
    }
  },
  emits: ['banner_buy'],
  data() {
    return {
      screenWidth: window.innerWidth,
      clicked_carousel: false,
      current_index: 0,
      carousel_items: null,
      carouselIntervalId: null,
      isDragging: false,
      startX: 0,
      deltaX: 0,
      initialIndex: 0,
      refresh_key: 0,
    };
  },
  async mounted() {
    window.addEventListener("resize", this.handleResize);
    this.carousel_items = document.querySelectorAll(".carousel__item--build");
    await this.$nextTick();
    setTimeout(() => {
      this.carouselIntervalId = setInterval(() => {
        if (!this.clicked_carousel) {
          this.nextSlide(); //
        }
      }, 6000);
    }, 500);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
    if (this.carouselIntervalId !== null) {
      clearInterval(this.carouselIntervalId);
    }
  },
  methods: {
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    srcsetDesktop(number) {
/*      return `
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=600`)} 600w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=800`)} 800w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=1000`)} 1000w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=1200`)} 1200w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=1400`)} 1400w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=1600`)} 1600w,
        ${require(`../assets/images/rebrandable/banner-main-${number}-desktop.webp?width=1800`)} 1800w
      `;*/
      return `https://quickeat-api.s3.amazonaws.com/media/elpeje/ux_web/base_assets/10122024_19_23_${number}.webp?cache_buster=10`
    },
    srcsetMobile(number) {
      // return `${require(`../assets/images/rebrandable/banner-main-${number}-mobile.webp`)}`;
      return `https://quickeat-api.s3.amazonaws.com/media/elpeje/ux_web/base_assets/10122024_19_27_${number}.webp?cache_buster=10`;
    },
    startDrag(event) {
      this.clicked_carousel = true
      this.refresh_key += 1
      this.isDragging = true;
      this.startX = event.type === "touchstart" ? event.touches[0].clientX : event.clientX;
      this.initialIndex = this.current_index; // Guarda el índice inicial
    },
    handleDrag(event) {
      if (this.isDragging) {
        this.clicked_carousel = true
        this.refresh_key += 1
        const clientX = event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
        this.deltaX = clientX - this.startX;
        // Ajusta la velocidad del deslizamiento según tus preferencias
        const slideWidth = this.carousel_items[this.current_index].offsetWidth + this.getMarginWidth();
        this.deltaX = this.deltaX / slideWidth * 100; // Convierte el desplazamiento en porcentaje
        this.updateCarousel();
      }
    },
    endDrag() {
      if (this.isDragging) {
        this.isDragging = false;
        if (Math.abs(this.deltaX) > 10) {
          if (this.deltaX > 0) {
            this.current_index = this.initialIndex - 1;
          }
          else {
            this.current_index = this.initialIndex + 1;
          }
          if (this.current_index < 0) {
            // Si el índice es menor que 0, vuelve al último índice
            this.current_index = this.carousel_items.length - 1;
          }
          else if (this.current_index >= this.carousel_items.length) {
            // Si el índice es mayor o igual al número de diapositivas, vuelve al primer índice
            this.current_index = 0;
          }
          this.clicked_carousel = true;
          this.refresh_key += 1
          this.updateCarousel();
        }
        this.deltaX = 0;
      }
    },
    goToSlide(index) {
      // Método para ir a una diapositiva específica
      this.clicked_carousel = true
      this.current_index = index;
      this.updateCarousel();
    },
    updateCarousel() {
      if (this.clicked_carousel) {
        let current_value = this.refresh_key
        setTimeout(() => {
          if (current_value === this.refresh_key) {
            this.clicked_carousel = false;
          }
        }, 8000);
      }
      let carousel_categories = null;
      let itemWidth = null;
      let offset = null;
      carousel_categories = document.querySelector(".carousel__list--build");
      itemWidth = this.carousel_items[this.current_index].offsetWidth + this.getMarginWidth();
      offset = -this.current_index * itemWidth;
      if (carousel_categories !== null) {
        carousel_categories.style.transform = `translateX(${offset}px)`;
      }
    },
    getMarginWidth() {
      let firstItem = this.carousel_items[0];
      const styles = window.getComputedStyle(firstItem);
      const marginLeft = parseFloat(styles.marginLeft);
      const marginRight = parseFloat(styles.marginRight);
      return marginLeft + marginRight;
    },
    nextSlide() {
      this.current_index++;
      if (this.current_index >= this.carousel_items.length) {
        this.current_index = 0;
      }
      this.updateCarousel();
    },
  },
  // components: { ButtonPrimary },
}
</script>

<style scoped>
.carousel-main--build{
  position: relative;
  background: var(--text-black--build);
}

.carousel__container--build{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
  user-select: none;
}

.carousel__list--build{
  height: 100%;
  display: flex;
  transition: 0.7s ease all;
}

.carousel__item--build{
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  transition: 0.7s ease all;
  min-width: 100%;
  max-width: 100%;
  color: var(--text-white--build);
  background-color: black;
}

.carousel__item--build picture{
  width: 100%;
  max-height: 70dvh;
  object-fit: cover;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  pointer-events: none;
}

.carousel__item--build img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
}

.carousel__information{
  position: relative;
  width: 100%;
  height: 70dvh;
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  background-size: cover;
}

.carousel__information button{
  background-color: transparent;
  border: 1px solid var(--text-white--build);
  color: var(--text-white--build);
}

/*.image__container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 250px;
  padding-top: 40px;
  margin-top: auto;
  border-radius: 50% 50% 25% 25% / 50% 50% 0% 0%;
  background-color: rgba(0, 0 ,0, .6);
  background-repeat: no-repeat;
  background-size: 600px;
  background-position: center 100%;
}*/

.image__container{ display: none; }

.carousel__list-dots--build {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

.carousel__item-dot--build {
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  background: var(--text-white--build);
  cursor: pointer;
  transition: background 0.3s;
}

.carousel__item-dot--build.active {
  background: var(--primary--build);
  transform: scale(1.5);
}

.desktop{
  display: none;
}

.mobile{
  display: flex;
}

@media only screen and (min-width: 600px){
  .desktop{ display: flex;}
  .mobile{ display: none;}
  .carousel__item--build picture{
    aspect-ratio: 16 / 7.5;
    max-height: 100%;
  }
  .carousel__information{
    position: relative;
    aspect-ratio: 16 / 7.5;
    height: 100%;
    justify-content: flex-end;
  }
  .carousel__item--build button{ margin-left: 250px; }
  .carousel__item--build:first-child button{ margin-left: 125px; }
  .image__container{
    width: 550px;
    height: 400px;
    padding-top: 120px;
    border-radius: 100% 0% 0% 100% / 100% 0% 100% 0% ;
    background-size: 800px;
    background-position: 100% 140%;
  }
  .carousel__list-dots--build {
    width: 100px;
    position: absolute;
    bottom: 40px;
    left: 40px;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1440px){
  .desktop{ display: flex;}
  .mobile{ display: none;}
  .carousel__item--build button{ margin-left: 280px; }
  .carousel__item--build:first-child button{ margin-left: 180px; }
  .carousel__information{
    justify-content: flex-end;
    background-position-y: 0%;
  }
  .image__container{
    width: 600px;
    height: 450px;
    padding-top: 180px;
    background-position: 100% 160%;
  }
}

</style>