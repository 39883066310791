<template>
  <div class="modal__bg--build" @click="closeModal">
    <div class="modal__container-md--build" @click.stop>
      <h2 class="tp-head-responsive--build">{{ $t('modalcancelbooking.txt_cancelacion_de_reserva') }}</h2>
      <p class="tp-body-responsive--build">{{ $t('modalcancelbooking.txt_se_anulara_su_reserva_y_se_le') }}</p>
      <div class="modal__buttons--build">
        <ButtonTexto
            type="button"
            @click="closeModal"
        >
          {{ $t('dropdownaddress.txt_regresar') }}
        </ButtonTexto>
        <ButtonPrimary
            type="button"
        >
          {{ $t('modalcancelbooking.txt_cancelar_reserva') }}
        </ButtonPrimary>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { defineEmits } from "vue"
  import ButtonTexto from "@/components/new/buttons/ButtonTextoConstructor.vue";
  import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";

  const emit = defineEmits(['close']);

  const closeModal = () => emit('close')
</script>

<style scoped>
h2,
p{
  text-align: center;
}
</style>