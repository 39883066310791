<template>
  <section class="gallery--build">
    <div class="gallery__container--build section-main--build">
      <div class="gallery__image-1--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_24_56.webp?cache_buster=0?width=200 200w,
                    https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_24_56.webp?cache_buster=0?width=400 400w,
                    https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_24_56.webp?cache_buster=0?width=600 600w,
                    https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_24_56.webp?cache_buster=0?width=800 800w,
                "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_24_56.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-2--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_21.webp?cache_buster=0?width=200 200w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_21.webp?cache_buster=0?width=400 400w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_21.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_21.webp?cache_buster=0?width=800 800w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_21.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-3--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_32.webp?cache_buster=0?width=200 200w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_32.webp?cache_buster=0?width=400 400w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_32.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_32.webp?cache_buster=0?width=800 800w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_32.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-4--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_41.webp?cache_buster=0?width=200 200w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_41.webp?cache_buster=0?width=400 400w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_41.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_41.webp?cache_buster=0?width=800 800w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_41.webp"
            sizes="(max-width: 600px) 100vw, 600px"
        />
      </div>
      <div class="gallery__image-5--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_50.webp?cache_buster=0?width=200 200w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_50.webp?cache_buster=0?width=400 400w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_50.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_50.webp?cache_buster=0?width=800 800w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_50.webp"
            sizes="(max-width: 600px) 100vw, 600px"
        />
      </div>
      <div class="gallery__image-6--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_58.webp?cache_buster=0?width=200 200w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_58.webp?cache_buster=0?width=400 400w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_58.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_58.webp?cache_buster=0?width=800 800w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_25_58.webp"
            sizes="(max-width: 600px) 100vw, 600px"
        />
      </div>
      <div class="gallery__image-7--build">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_26_08.webp?cache_buster=0?width=600 600w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_26_08.webp?cache_buster=0?width=800 800w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_26_08.webp?cache_buster=0?width=1000 1000w,
                  https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_26_08.webp?cache_buster=0?width=1200 1200w,
              "
            src="https://quickeat-api.s3.amazonaws.com/media/quickeat/ux_web/base_assets/02012025_15_26_08.webp?cache_buster=0"
            sizes="(max-width: 1200px) 100vw, 1200px"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.gallery--build{ background-color: var(--bg-home); }

.gallery__container--build{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 50vw 50vw 75vw;
  gap: 8px;
}

[class^="gallery__image"]{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

[class^="gallery__image"] > img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.gallery__image-5--build{
  grid-column: 1 / -1;
  grid-row: 1 / 2;
}

.gallery__image-6--build{
  grid-column: 1 / -1;
  grid-row: 2 / 3;
}

.gallery__image-1--build{
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.gallery__image-2--build{
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.gallery__image-3--build,
.gallery__image-4--build,
.gallery__image-7--build{
  display: none;
}

@media screen and (min-width: 600px){
  .gallery__container--build{
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 25vw 55vw;
    gap: 14px;
  }

  .gallery__image-1--build{
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }

  .gallery__image-2--build{
    grid-column: 3 / 5;
    grid-row: 2 / 3;
  }

  .gallery__image-3--build{
    display: block;
    grid-column: 5 / -1;
    grid-row: 2 / 3;
  }

  .gallery__image-4--build{
    display: none;
  }

  .gallery__image-5--build{
    grid-column: 1 / 4;
    grid-row: 1 / 2;
  }

  .gallery__image-6--build{
    grid-column: 4 / -1;
    grid-row: 1 / 2;
  }
}

@media screen and (min-width: 905px){
  .gallery__container--build{
    gap: 24px;
  }
}

@media screen and (min-width: 1240px){
  .gallery__container--build{
    grid-template-rows: 500px 250px 250px 475px;
    gap: 40px;
  }

  .gallery__image-1--build{
    grid-row: 1 / 2;
  }

  .gallery__image-2--build{
    grid-row: 1 / 2;
  }

  .gallery__image-3--build{
    grid-row: 1 / 2;
  }

  .gallery__image-4--build{
    display: block;
    grid-column: 1 / 4;
    grid-row: 2 / 4;
  }

  .gallery__image-5--build{
    grid-column: 4 / -1;
    grid-row: 2 / 3;
  }

  .gallery__image-6--build{
    display: block;
    grid-row: 3 / 4;
  }

  .gallery__image-7--build{
    display: block;
    grid-column: 1 / -1;
    grid-row: 4 / -1;
  }
}
</style>