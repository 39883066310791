<template>
  <div class="modal__bg--build" @click="closeModal">
    <form @submit.prevent="updateUserPhone" class="modal__container-md--build" @click.stop>
      <h2 class="tp-head-responsive--build">{{ $t('modalphoneinput.txt_ultimo_paso') }}</h2>
      <p class="tp-body-responsive--build">{{ $t('modalphoneinput.txt_ingresa_tu_numero_de_celular') }}</p>
      <InputComponent
        :disabled=false
        :placeholder="$t('placeholder.txt_numero_de_celular')"
        :type="'tel'"
        :required=true
        :minlength="'9'"
        :maxlength="'9'"
        :bg_color="'var(--text-white--build)'"
        v-model="phoneNumber"
    >
    </InputComponent>
      <ButtonPrimary
          type="submit"
          :loading="loading"
      >
        {{txtBtnPhone()}}
      </ButtonPrimary>
    </form>
  </div>
</template>

<script>

import InputComponent from "@/components/new/inputs/InputComponent.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
import UserServices from "@/services/user-services";
import {mapMutations} from "vuex";

export default {
  name: 'ModalPhoneInput',
  emits: ['closePhoneModal'],
  components: {
    ButtonPrimary,
    InputComponent,
  },
  data() {
    return {
      phoneNumber: null,
      loading: false,
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    txtBtnPhone() {
      if (this.loading) {
        return this.$t('forgotpassword.txt_cargando_dat');
      }
      return this.$t('buttons.txt_confirmar');
    },
    async updateUserPhone() {
      if (this.phoneNumber === null || this.phoneNumber.length !== 9) {
        this.$toast.error(this.$t('toast.txt_debe_ingresar_un_numero_celular'))
        return
      }
      let response2 = await UserServices.updateInfoUser({
        phone: this.phoneNumber}
      )
      if (response2.data.success) {
        this.$toast.success(this.$t('toast.txt_numero_celular_actualizado_con_exito'))
        this.setUser(response2.data.data)
        this.loading = false
        this.closeModal()
        return
      }
      this.$toast.error(response2.data.message)
      this.loading = false
    },
    closeModal() {
      this.$emit('closePhoneModal')
    }

  },
}
</script>

<style scoped>
.modal__bg--build h2,
.modal__bg--build p{
  text-align: center;
}
</style>