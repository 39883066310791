import { createWebHistory, createRouter, createMemoryHistory } from "vue-router";

const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();

import store from '../store/index';
import User from '../services/user-services';
import Home from '../views/Home.vue';
import CambiarClave from '../views/CambiarClave.vue';
import RastreoPedido from '../views/RastreoPedido.vue';
import LibroReclamaciones from '../views/LibroReclamaciones.vue';
import Unsubscribed from '../views/Unsubscribed.vue';
import ApplyJob from '../views/ApplyJob.vue';
import CartaRestaurante from '../views/CartaRestaurante.vue';
import NuestraCarta from '../views/NuestraCarta.vue';
import PaymentScreen from '../views/PaymentScreen.vue';
import Perfil from '../views/Perfil/Perfil.vue';
import PerfilMiCuenta from '../views/Perfil/PerfilMiCuenta.vue';
import PerfilMiCuentaUser from '../views/Perfil/PerfilMiCuentaUser.vue';
import PerfilMiCuentaLugares from '../views/Perfil/PerfilMiCuentaLugares.vue';
import PerfilCambiarClave from '../views/Perfil/PerfilCambiarClave.vue';
import PerfilMisBeneficios from '../views/Perfil/PerfilMisBeneficios.vue';
import PerfilMisGestionNotificaciones from '../views/Perfil/PerfilMisGestionNotificaciones.vue';
import PerfilMisPedidos from '../views/Perfil/PerfilMisPedidos.vue';
import PerfilMisReservas from '../views/Perfil/PerfilMisReservas.vue';
import Cobertura from '../views/Cobertura.vue';
import Reservas from '../views/Reservas.vue';
import WhatsApppaymentscreen from '../views/WhatsAppPaymentScreen'
import EveryUIKit from '../views/every_ui_kit.vue';

const routes = [
  {
    path: '/',
    query: { fast_register: '',  },
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/cambiar',
    query: { token: '' },
    name: 'cambiar',
    component: CambiarClave,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/rastreoPedido',
    query: {
      qr_code: '',
      to_review: false,
    },
    name: 'rastreoPedido',
    component: RastreoPedido,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/libro_rec',
    name: 'libro_rec',
    component: LibroReclamaciones,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/unsubscribed',
    name: 'unsubscribed',
    component: Unsubscribed,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/apply_job',
    name: 'apply_job',
    component: ApplyJob,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/trabajo',
    name: 'trabajo',
    component: ApplyJob,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/restaurantes/:idLocal-:texto',
    name: 'CartaRestaurante',
    component: CartaRestaurante,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/menudigital',
    name: 'menudigital',
    component: NuestraCarta,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/menudigital/:idCategory',
    name: 'menudigitalcat',
    component: NuestraCarta,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/menudigital/:idCategory/:idProduct',
    name: 'menudigitalprod',
    component: NuestraCarta,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/micarrito/pagar',
    name: 'micarrito.pagar',
    component: PaymentScreen,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: Perfil,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'cuenta',
        component: PerfilMiCuenta,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '',
            name: 'perfil.cuenta',
            component: PerfilMiCuentaUser,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'direcciones',
            name: 'perfil.cuenta.direcciones',
            component: PerfilMiCuentaLugares,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: 'clave',
        name: 'perfil.clave',
        component: PerfilCambiarClave,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'beneficios',
        name: 'perfil.beneficios',
        component: PerfilMisBeneficios,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'notificaciones',
        name: 'perfil.notificaciones',
        component: PerfilMisGestionNotificaciones,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'pedidos',
        name: 'pedidos',
        component: PerfilMisPedidos,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'misreservas',
        name: 'misreservas',
        component: PerfilMisReservas,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'direcciones',
        name: 'direcciones.solo',
        component: PerfilMiCuentaLugares,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/locales',
    name: 'locales',
    component: Cobertura,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/WhatsApppaymentscreen',
    name: 'WhatsApppaymentscreen',
    component: WhatsApppaymentscreen,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/reservas',
    name: 'reservas',
    component: Reservas,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/every_ui_kit',
    name: 'every_ui_kit',
    component: EveryUIKit,
    meta: {
      requiresAuth: false,
    },
  },
];
const router = createRouter({
  history: history,
  routes: routes,
});

router.onError(error => {
  const lowercase = error.message.toLowerCase();
  console.log("el error del router es", lowercase);
  const condition = lowercase.includes('chunk failed');
  const condition2 = lowercase.includes('loading chunk');
  const condition3 = lowercase.includes('loading css chunk');
  const condition4 = lowercase.includes('loading css');

  if (condition || condition2 || condition3 || condition4 && !isServer) {
    setTimeout(() => {
      window.alert('Se actualizará la web. Por favor, vuelva a intentarlo cuando termine de cargar.');
      window.location.reload();
    }, 1900);
  } else {
    if (!isServer) {
      localStorage.clear();
      window.location.reload();
    }
  }
});

let firstOcurrency = true;
async function loadLocalStorageData() {
  let orderDirection = await JSON.parse(localStorage.getItem("orderDirection"));
  if (orderDirection) {
    store.commit("setOrderDirection", orderDirection);
  }
  let orderLocal = await JSON.parse(localStorage.getItem("orderLocal"));
  if (orderLocal) {
    store.commit("setOrderLocal", orderLocal);
  }
  let listLocals = await JSON.parse(localStorage.getItem("listLocals"));
  if (listLocals) {
    store.commit("setListLocals", listLocals);
  }
  let typeOrder = await JSON.parse(localStorage.getItem('typeOrder'));
  if (typeOrder) {
    store.commit('setTypeOrder', typeOrder)
  }
  let shoppingCartInfo = await JSON.parse(localStorage.getItem('shoppingCartInfo'));
  if (shoppingCartInfo) {
    store.commit('setShoppingCartInfo', shoppingCartInfo)
  }
  let webVersion = await JSON.parse(localStorage.getItem("webVersion"));
  if (webVersion) {
    store.commit("setWebVersion", webVersion)
  }
  let businessInfo = await JSON.parse(localStorage.getItem("businessInfo"));
  if (businessInfo) {
    store.commit("setBusinessInfo", businessInfo)
  }
  let user = await JSON.parse(localStorage.getItem("user"));
  if (user) {
    await User.setAuthHeaders(user.token);
    store.commit("setUser", user);
    store.commit("setAuthenticatedTrue");
  } else {
    store.commit("setAuthenticatedFalse");
    //router.push("/")
  }
}

router.beforeEach(async (to, from, next) => {
  if (firstOcurrency && !isServer) {
    await loadLocalStorageData();
    firstOcurrency = false;
  }

  const isProtectedRoute = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = store.state.isAuthenticated;

  if (isProtectedRoute) {
    if (isAuthenticated) {
      next();
      return
    }
    await router.push("/");
    next(false);
    return
  }
  next();
});
export default router;
