<template>
  <div class="mobile__component" @click="openShoppingCartModal" :class="{ 'mobile__component-active': shoppingCartInfo.length > 0}">
    <ButtonPrimary>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 22C6.45 22 5.97917 21.8042 5.5875 21.4125C5.19583 21.0208 5 20.55 5 20C5 19.45 5.19583 18.9792 5.5875 18.5875C5.97917 18.1958 6.45 18 7 18C7.55 18 8.02083 18.1958 8.4125 18.5875C8.80417 18.9792 9 19.45 9 20C9 20.55 8.80417 21.0208 8.4125 21.4125C8.02083 21.8042 7.55 22 7 22ZM17 22C16.45 22 15.9792 21.8042 15.5875 21.4125C15.1958 21.0208 15 20.55 15 20C15 19.45 15.1958 18.9792 15.5875 18.5875C15.9792 18.1958 16.45 18 17 18C17.55 18 18.0208 18.1958 18.4125 18.5875C18.8042 18.9792 19 19.45 19 20C19 20.55 18.8042 21.0208 18.4125 21.4125C18.0208 21.8042 17.55 22 17 22ZM6.15 6L8.55 11H15.55L18.3 6H6.15ZM5.2 4H19.95C20.3333 4 20.625 4.17083 20.825 4.5125C21.025 4.85417 21.0333 5.2 20.85 5.55L17.3 11.95C17.1167 12.2833 16.8708 12.5417 16.5625 12.725C16.2542 12.9083 15.9167 13 15.55 13H8.1L7 15H19V17H7C6.25 17 5.68333 16.6708 5.3 16.0125C4.91667 15.3542 4.9 14.7 5.25 14.05L6.6 11.6L3 4H1V2H4.25L5.2 4Z" fill="currentColor"/>
      </svg>
      {{ $t('mobilefootercomponent.txt_ver_carrito') }} ({{shoppingCartInfo.length}})
    </ButtonPrimary>
    <p>{{ $t('modalmicarrito.txt_total_double_dot') }} S/{{parseFloat(total).toFixed(2)}}</p>
  </div>
</template>

<script>
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
import {mapMutations, mapState} from "vuex";

export default {
  name: "mobileFooterComponent",
  components: {ButtonPrimary},
  data () {
    return {

    }
  },
  mounted() {

  },
  watch: {
    shoppingCartInfo: {
      async handler() {

      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['shoppingCartInfo']),
    total: function () {
      let suma = 0
      this.shoppingCartInfo.forEach(product => {
        suma = suma + (product.price + product.extras - product.item_discount) * product.cant
      })
      return suma
    },
  },
  methods: {
    ...mapMutations(['setShowShoppingCart']),
    openShoppingCartModal() {
      this.setShowShoppingCart(true)
    },
  }
}
</script>

<style scoped>
  .mobile__component{
    display: none;
    cursor: pointer;
  }

  .mobile__component-active{
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px 16px;
    z-index: 5;
    background-color: white;
    box-shadow: 0 0 8px rgba(41, 41, 41, .2)
  }

  .mobile__component p{ font-size: 1.125rem; }

  @media only screen and (min-width: 768px){
    .mobile__component{ display: none; }
  }
</style>