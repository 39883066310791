<template>
<div class="fondo">
      <div class="square">
        <p class="titulo">{{ $t('cambiarclave.txt_escribe_tu_nueva_contrasena') }}</p>
        <p>{{ $t('cambiarclave.txt_tu_nueva_contrasena_debe') }}</p>
        <PasswordComponent
          v-model="password1"
          :required=true
          :placeholder="$t('placeholder.txt_nueva_contrasena')"
          :bg_color="'var(--text-white--build)'">
        </PasswordComponent>
        <PasswordComponent
            v-model="password2"
            :required=true
            :placeholder="$t('placeholder.txt_confirmar_nueva_contrasena')"
            :bg_color="'var(--text-white--build)'">
        </PasswordComponent>
        <ButtonPrimary
          type="button"
          @click="resetearClave"
          :loading="loadingCambiar">
          {{messageToken}}
        </ButtonPrimary>
      </div>

</div>
</template>

<script>
import User from "@/services/user-services"
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
import PasswordComponent from "@/components/new/inputs/PasswordComponent";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";
export default {
  name: "CambiarClave.vue",
  components: {
    PasswordComponent,
    ButtonPrimary,
  },
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: "Cambiar clave",
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Cambiar contraseña mediante e-mail.`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
data () {
  return {
    password1: '',
    password2: '',
    tokenCorreo:'',
    loadingCambiar: false
  }},


  methods: {
    async resetearClave() {
      var url_string = window.location.href
      var url = new URL(url_string);
      var token = url.searchParams.get("token");
      this.tokenCorreo = token
      if (this.password1.length < 6) {
        this.$toast.error(this.$t('perfilcambiarclave.txt_la_contrasena_debe_tener'))
      } else {
      if (this.password1 === this.password2){
        this.loadingCambiar = true
        let response = await User.resetPassword({token: this.tokenCorreo, password: this.password1, password2: this.password2})

        if(response.data.success) {
          console.log(response.data)
          this.$toast.success(this.$t('perfilcambiarclave.txt_se_cambio_su_contrasena'))
          this.loadingCambiar=false

          setTimeout(() => {
            this.$router.push("/")
            this.loading = false
          }, 1500)
        } else {
          console.log(response.data)
          this.$toast.error(response.data.message)
          this.loadingCambiar=false
        }


      } else {
        this.$toast.error(this.$t('cambiarclave.txt_la_contrasena_debe_ser_igual'))

      }}
    },
      },

  computed: {
    messageToken() {
      if (this.loadingCambiar) {
        return this.$t('forgotpassword.txt_cargando_dat')
      } else {
        return this.$t('forgotpassword.txt_cambiar_contrasena')
      }
    },
  }


  }
</script>

<style scoped>

.fondo {
  background: #F7F7F7;
  width: 100%;
  display: flex;
  flex-direction: column;

}

p {
  font-size: 1.3em;
  margin-top: 10px;
}

span {
  padding-right: 5%;
  color: #ff1542;
  font-weight:bold;
  text-align:right;
}

.square {
  padding: 50px;
  width: 550px;
  margin: 50px auto;
  z-index: 10;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0 4px 6px -6px #222;
  border-radius:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}
.square .titulo {
  width: 100%;
  text-align: center;
  color: var(--primary--build);
  font-weight: bold;
  font-size: 1.5em;
}

.square label {
  color: #22252A;
  font-weight: bold;
  text-align: left;
  width: 90%;

}

.square p {

  font-size: 1em;
  width: 90%;
  text-align: left;
  color: #22252A;
  margin-inline: auto;
}

@media only screen and (max-width: 768px) {
  .square {
    width: 90vw;
  }

}



</style>