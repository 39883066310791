<template>
  <div>
    <label class="percent">
    </label>
    <div id="cont">
      <svg
        id="svg"
        width="150"
        height="150"
        viewPort="0 0 100 100"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          id="bg"
          r="60"
          cx="70"
          cy="70"
        ></circle>
        <circle
          id="bar"
          r="62"
          cx="120"
          cy="140"
          transform="rotate(180 95 105)"
        ></circle>
      </svg>
    </div>
  </div>
</template>

<script>
//import IconLogo from "@/assets/icons/logo"
export default {
  props: ['points'],
  components: {
    //IconLogo
  },
  data () {
    return {
    }
  },
  computed: {
    percent: function () {
      return Math.round(this.points / 10)
    }
  },
  watch: {
    points: function () {
      this.paintCircle()
    }
  },
  mounted () {
    this.paintCircle()

  },
  methods: {
    paintCircle () {
      let self = this
      let cont = document.getElementById('cont');
      let bg = document.getElementById('bg');
      let circle = document.getElementById('bar');
      bg.style.strokeDasharray =
        circle.style.strokeDasharray = this.getCirclePerimeter();
      cont.setAttribute('data-pct', this.points);//setea el valor de los points
      //let perimeter = self.getCirclePerimeter();

      //      var offset = perimeter * (100 - self.percent) / 100;
      let offset = 514.59 - 4.637 * self.percent

      circle.style.strokeDashoffset = offset;
      cont.setAttribute('data-pct', self.points);//pone el valor de los points
    },
    getCirclePerimeter () {
      let circle = document.getElementById('bar');
      let radius = parseInt(circle.getAttribute('r'), 10);
      return 2 * Math.PI * radius;
    }
  }
}
</script>

<style scoped>
  .percent{ position: absolute; }

  #svg circle{
    fill: white;
    stroke-dashoffset: 0;
    transition: stroke-dashoffset 1s linear;
    stroke: #BDBDBD;
    stroke-width: 15px;
  }

  #svg #bar{
    stroke: var(--primary--build);
    stroke-width: 6px;
  }

  #cont{
    height: 150px;
    width: 150px;
    margin: 10px auto;
    position: relative;
  }

  #cont > img{
    position: absolute;
    transform: scale(0.65);
    bottom: -10%;
    width: 50%;
    left: 25%;
    border-radius: 15px;
  }

  #cont:after{
    position: absolute;
    display: block;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);
    content: attr(data-pct);
    font-size: 3.25rem;
    font-weight: 500;
    color: #383838;
    font-family: var(--second-typography--build);
  }

</style>