<template>
<!--    <WhatsappIconComponent-->
<!--        :phone=whatsapp_phone-->
<!--    ></WhatsappIconComponent>-->
  <CarouselQE
      :info_carousel_desktop="info_carousel_desktop"
      :info_carousel_mobile="info_carousel_mobile"
      @banner_buy="$router.push('/menudigital/')"
  >
  </CarouselQE>
  <section class="carta--build">
    <div class="carta__container--build section-main--build">
      <h2 class="tp-head-responsive--build">{{ $t('home.txt_nuestra_carta') }}</h2>
      <section class="carta__list--build">
        <CategoriasCarta
            v-for="(item, index) in listCartas"
            :category=item
            :key="index"
            @click="loadCard(item.id)"
        >
        </CategoriasCarta>
      </section>
      <ButtonSecundary
          type="button"
          @click="loadCard(null)"
      >
        {{ $t('buttons.txt_ordenar_ahora') }}
      </ButtonSecundary>
    </div>
  </section>
  <aside class="aside--build" v-if="false">
    <div class="aside__background--build">
      <div class="aside__text-image--build">
        <picture>
          <source media="(max-width: 599px)" srcset="../assets/images/rebrandable/aside_text_mobile.png" />
          <source media="(min-width: 600px)" srcset="../assets/images/rebrandable/aside_text_desktop.png" />
          <img class="text" src="../assets/images/rebrandable/aside_text_desktop.png" alt="Descargar nuestra App" />
        </picture>
        <div class="aside__apps-mobile">
          <a href="https://apps.apple.com/es/app/barrilito-express/id1524896987" target="_blank"><img src="../assets/images/rebrandable/aside_appstore.svg" alt="App store"></a>
          <a href="https://play.google.com/store/apps/details?id=cquickeat.elbarrilitoexpress" target="_blank"><img src="../assets/images/rebrandable/aside_googleplay.svg" alt="Google play"></a>
        </div>
      </div>
      <picture>
        <source
            type="image/webp"
            srcset="https://quickeat.s3.us-west-2.amazonaws.com/local/Barrilito+Express/new_web_files/aside-desktop.webp"
            media="(min-width: 600px)"
            sizes="(max-width: 1920px) 100vw, 1920px"
        />
        <img
            loading="lazy"
            role="presentation"
            alt=""
            src="../assets/images/rebrandable/aside-bg-hero-mobile.webp"
            sizes="(max-width: 600px) 100vw, 600px"
        />
      </picture>
    </div>
  </aside>
  <GalleryComponent/>
  <section class="register--build">
    <div class="register__container--build section-main--build">
      <h3 class="register__subtitle--build">{{ $t('home.txt_quieres_estar_al_tanto_de') }} <br>{{ $t('home.txt_registrate') }}</h3>
      <ButtonPrimary
          type="button"
          @click="registerNowClicked"
      >
        {{ $t('buttons.txt_quiero_registrarme') }}
      </ButtonPrimary>
    </div>
  </section>
  <transition name="slide-fade">
    <LocalModalSelect
        v-if="showModalLocal"
        :ok="ModalLocal.ok"
        :no="ModalLocal.no"
        :message="ModalLocal.message"
        @ok="goToMenu"
        @no="showModalLocal=false"
    ></LocalModalSelect>
  </transition>
  <transition name="slide-fade">
    <ModalLocalSchedules
        v-if="showModalLocalSchedules"
        :ok="ModalLocal.ok"
        :no="ModalLocal.no"
        @ok="goToMenu"
        @no="showModalLocalSchedules=false"
    >
    </ModalLocalSchedules>
  </transition>
  <transition name="slide-fade">
    <ModalPhoneInput
        v-if="showModalPhoneInput"
        @closePhoneModal="showModalPhoneInput=false"
    >
    </ModalPhoneInput>
  </transition>
  <transition name="slide-fade">
    <LoadingBigComponent v-if="loading"/>
  </transition>
</template>

<script>

import LocalServices from "@/services/local-services"
import {mapMutations, mapState} from "vuex";
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import CategoriasCarta from "@/components/Recursos/CategoriasCarta.vue";
import CarouselQE from "@/components/CarouselQE.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimaryConstructor.vue";
import LocalModalSelect from "@/components/MODALS/LocalModalSelect";
import ModalLocalSchedules from "@/components/MODALS/ModalLocalSchedules"
import ModalPhoneInput from "@/components/MODALS/ModalPhoneInput";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundaryConstructor.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";
import GalleryComponent from "@/components/GalleryComponent";

export default {
  name: 'Home',
  components: {
    GalleryComponent,
    ButtonSecundary,
    LoadingBigComponent,
    ModalLocalSchedules,
    LocalModalSelect,
    ButtonPrimary,
    CarouselQE,
    CategoriasCarta,
    // WhatsappIconComponent,
    ModalPhoneInput,
},
  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Inicio`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Bienvenido a ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async created () {
    this.is_ssr = typeof window === 'undefined';
  },
  async mounted() {
    this.getWhatsappPhone()

    let url_string = window.location.href;
    let url = new URL(url_string);

    let temp_track = url.searchParams.get("fast_register")
    if (temp_track !== undefined && temp_track !== null) {
      this.setShowModalLoginRegister(true)
    }
    await this.getLocals()
    await this.getCategories()
    if (!this.is_ssr) {
      window.scrollTo(0, 0)
    }
    this.loading = false
  },
  data () {
    return {
      listCartas: [],
      homeLocalList: [],
      info_carousel_desktop: [16, 29 , 42],
      info_carousel_mobile: [11, 23 , 33],
      showModalLocal: false,
      showModalLocalSchedules: false,
      loading: true,
      ModalLocal: {
        ok: '',
        no: '',
        message: ''
      },
      category_home: 0,
      whatsapp_phone: '',
      showModalPhoneInput: false,
      is_ssr: false,
    }
  },

  computed: {
    ...mapState(['isAuthenticated', 'user', 'listLocals', 'orderLocal', 'isSelfService'])
  },
  methods: {
    ...mapMutations(['setUser', 'setAuthenticatedTrue', 'setShowModalLoginRegister', 'setOrderLocal']),
    registerNowClicked() {
      if (!this.isAuthenticated) {
        this.setShowModalLoginRegister(true)
        return
      }
      this.$toast.info(this.$t('home.txt_ya_se_encuentra_registrado'))
    },
    getWhatsappPhone() {
      this.whatsapp_phone = process.env.VUE_APP_WHATSAPP_PHONE
    },
    async getLocals() {

      let response = await LocalServices.listLocalesCercanos()
      if (response.data.success) {
        response.data.data = response.data.data.filter(item => {
          if (!item.deleted) {
            return item
          }
        })
        for (let i = 0; i < response.data.data.length; i++) {
          const dayLists = [[], [], [], [], [], [], []];

          for (let g = 0; g < response.data.data[i].schedules.length; g++) {
            const day = parseInt(response.data.data[i].schedules[g].day, 10);
            dayLists[day].push(response.data.data[i].schedules[g]);
          }
          for (let z=0; z < dayLists.length; z++) {
            if (dayLists[z].length > 0) {
              dayLists[z] = dayLists[z].sort(function (a, b) {
                if (a.time_init > b.time_init) {
                  return 1;
                }
                if (a.time_init < b.time_init) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            }
          }
          response.data.data[i].day_list = dayLists
        }
        this.homeLocalList = response.data.data
        if (this.orderLocal == null || !this.isSelfService) {
          this.setOrderLocal(this.homeLocalList.find(local => local.id === parseInt(process.env.VUE_APP_FIRST_LOCAL_ID)))
        }

        let matchingLocal = this.homeLocalList.find(local => local.id === this.orderLocal.id);

        if (matchingLocal) {
          this.setOrderLocal(matchingLocal);
        } else {
          if (this.homeLocalList.length > 0) {
            this.setOrderLocal(this.homeLocalList[0])
          } else {
            this.setOrderLocal(null)
          }
        }

      }

    },

    loadCard (category_id) {
      this.category_home = category_id
      if (!this.orderLocal.is_available_local) {
        this.ModalLocal.message = this.$t('home.txt_local_saturado_lo_sentimos');
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocal = true
        return
      }
      if (!this.orderLocal.is_in_area) {
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_ver_carta');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_regresar');
        this.showModalLocalSchedules = true
        return
      }
      if (this.orderLocal.is_available_message) {
        this.ModalLocal.message = this.orderLocal.message
        this.ModalLocal.ok = this.$t('dropdownaddress.txt_si');
        this.ModalLocal.no = this.$t('dropdownaddress.txt_no');
        this.showModalLocal = true
        return
      }
      this.goToMenu()
    },

    goToMenu() {
      if (this.category_home !== null) {
        this.$router.push(`/menudigital/${this.category_home}`)
        return
      }
      this.$router.push({name: 'menudigital'})
    },
    async getCategories () {
      try {
        let response = await LocalServices.listCategoriesOfLocal(this.orderLocal.id)
        if (response.data.success) {
          let promo = response.data.data.filter(carta => {
            if (!carta.deleted && carta.is_promotion) {
              return carta
            }
          })
          promo.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          let normal = response.data.data.filter(carta => {
            if (!carta.deleted && !carta.is_promotion) {
              return carta
            }
          })
          normal.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.listCartas = promo.concat(normal)
        }
      } catch (error) {
        console.log(error)
      }
    },
    openLink (to) {
      switch (to) {
        case 'android':
          window.open('https://play.google.com/store/apps/details?id=cquickeat.elbarrilitoexpress', '_blank')
          break;
        case 'iOS':
          window.open('https://apps.apple.com/es/app/barrilito-express/id1524896987', '_blank')
          break
      }
    },
  }
}
</script>

<style>
  /* ------------ INICIO DE SECCIÓN CARTA ------------ */
  .carta--build{
    background-color: var(--text-black--build);
    background-image: url("https://quickeat-api.s3.amazonaws.com/media/elpeje/ux_web/base_assets/elpeje_category_bg_img_10122024_19_57_25.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .carta__container--build{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .carta__container--build > h2{
    color: var(--text-white--build);
  }

  .carta__list--build{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .carta .button--build{
    text-transform: uppercase;
  }
  /* ------------ FIN DE SECCIÓN CARTA ------------ */

  /* ------------ INICIO DE ASIDE APP ------------ */
  .aside--build{
    height: auto;
    max-height: 75dvh;
    overflow: hidden;
  }

  .aside--build img{
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
  }

  .aside__background--build{ position: relative; }

  .aside__background--build img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .aside__text-image--build{
    position: absolute;
    top: 0;
    left: 0;
  }

  .aside__apps-mobile{
    display: flex;
    justify-content: center;
    gap: 16px;
    margin-top: -60px;
  }

  .aside__apps-mobile img{
    width: 150px;
    cursor: pointer;
  }

  .aside__apps-desktop{ display: none; }

  /* ------------ FIN DE ASIDE APP ------------ */

  /* ------------ INICIO DE SECCIÓN REGISTER ------------ */
  .register--build{
    display: none;
    background-image: url("https://quickeat.s3.us-west-2.amazonaws.com/local/Man+Man+Chi/new_web/about-img-5.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 0px 250px rgba(0, 0, 0, .6) inset;
    margin-bottom: 50px;
  }

  .register__container--build{
    text-align: center;
  }

  .register__subtitle--build{
    font-size: 1.125rem;
    line-height: 1.2;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    color: var(--text-white--build);
  }

  .register__container--build button{
    margin-left: auto;
    margin-right: auto;
  }

  /* ------------ FIN DE SECCIÓN REGISTER ------------ */

  @media screen and (min-width: 600px){
    .carta__list--build{
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
    }

    .aside--build{
      max-height: 100%;
      aspect-ratio: 16 / 7.5;
    }

    .aside__text-image--build{
      width: 45%;
      left: 5%;
    }

    .aside__text-image--build img{
      width: 45vw;
    }

    .aside__apps-mobile{ margin-top: -30px; }

    .aside__apps-mobile img{
      width: 25vw;
    }
  }

  @media screen and (min-width: 905px){
    .carta__list--build{
      gap: 16px;
    }

    .aside__apps-mobile{ margin-top: -45px; }
  }

  @media screen and (min-width: 1240px){
    .carta__list--build{
      gap: 24px;
    }

    .aside__text-image--build{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 40%;
      transform: translateY(-50%);
    }

    .aside__text-image--build img{
      width: 35vw;
    }

    .aside__apps-mobile{
      width: fit-content;
    }

    .aside__apps-mobile img{
      width: 20vw;
    }
  }
</style>